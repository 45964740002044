import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/Affiliate/Affiliate.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/global/animation-container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/pricing-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LampContainer"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/ui/lamp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/sites/wpspotlight.pro/src/components/ui/magic-card.tsx");
